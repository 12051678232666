import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {navigator, router} from 'lit-element-router';
import {repeat} from 'lit/directives/repeat.js';
import {when} from 'lit/directives/when.js';
import {msg} from '@lit/localize';
import deepEqual from 'deep-equal';
import {trimObject} from '../../util/trimObject';

import {routeUrl} from '../../util/routeUrl';
import store from '../../store';
import style from '../../../../scss/artworkDetail.scss';
import formPageStyle from '../../../../scss/formPage.scss';
import styleMeasurements from '../../../../scss/measurements.scss';
import loadingSpinnerStyle from '../../../../scss/loadingSpinner.scss';
import keyValueListStyle from '../../../../scss/keyValueList.scss';
import viewPdf from '../../icons/viewPdf';
import viewVideo from '../../icons/viewVideo';
import volume from '../../icons/volume';

import getRoutes from '../../routes';
import {
	fetchArtwork,
	fetchArtworkTypes,
	selectArtworkById,
	updateArtwork,
	fetchMaterials,
	fetchMediaCollection,
	createMedia,
	selectAllMedia,
	deleteMedia,
	fetchSubArtworks,
	createChildArtwork,
	clearMediaCollection,
	selectAllMaterials,
	selectAllArtworkTypes,
	selectAllSerie,
	fetchSeries,
	deleteArtwork,
	selectAllSubArtwork,
} from '../../slices/artworkSlice';
import {getValueForLanguage} from '../../util/getValueForLanguage';
import {deepClone} from '../../util/deepClone';
import {selectArtworkQuery, selectProfileDefaultLanguage, selectTenant} from '../../selectors';
import plus from '../../icons/plus';
import arrowRight from '../../icons/arrowRight';
import {navigateTo, openDialog, setFormChanged} from '../../slices/statusSlice';
import {isPermitted} from '../../util/isPermitted';
import {getTenantData} from '../../../tenants';

@customElement('arc-artwork-detail')
export class ArtworkDetail extends connect(store)(router(navigator(LitElement))) {
	static styles = [formPageStyle, styleMeasurements, loadingSpinnerStyle, keyValueListStyle, style];

	static properties = {
		localFormData: {type: Object, hasChanged: (n, o) => !deepEqual(n, o)},
		media: {type: Array},
		artworkTypes: {type: Array},
	};

	publicationStateOptions = [
		{value: 'draft', label: msg('Draft')},
		{value: 'private', label: msg('Private')},
		{value: 'public', label: msg('Publication permitted')},
	];

	constructor() {
		super();
		this.updateNeeded = false;
		this.localFormData = null;
		this.artworkTypes = [];
		this.materials = [];
		this.media = [];
		this.currentLanguage = 'de';
		this.componentActive = true;

		this.edit = true;
		this.isLoading = false;
		this.artworkId = null;
		store.subscribe(() => this.requestUpdate());
	}

	async stateChanged(state) {
		this.state = state;
		this.tenant = selectTenant(state);

		this.status = state.status;
		this.errors = state.artwork.error;
		this.isLoading = state.artwork.loading === 'loading';
		this.formChanged = state.status.formChanged;
		this.currentLanguage = selectProfileDefaultLanguage(state);

		this.permittedToView = isPermitted(['artwork/view'], this.profileId, state);
		this.permittedToEdit = isPermitted(['artwork/edit'], this.profileId, state);

		// collect data from state
		this.artworkTypes = selectAllArtworkTypes(state);

		// new array of artwork types with empty option at the beginning
		this.artworkTypesInclEmpty = [
			{
				id: 0,
				name: [
					{lang: 'de', value: 'Gattung wählen ...'},
					{lang: 'en', value: 'Choose Genre ...'},
				],
			},
			...this.artworkTypes,
		];

		this.materials = selectAllMaterials(state);
		this.artworkQuery = selectArtworkQuery(state);

		// make sure to filter media and childs by artwork id
		this.mediaCollection = selectAllMedia(state).filter(
			(artwork) => artwork.artworkId === this.artworkId
		);
		this.childArtworks = selectAllSubArtwork(state).filter(
			(artwork) => artwork.parentId === this.artworkId
		);

		// if it has a parent, also load parent artwork data to get the title
		if (this.localFormData?.parentId) {
			this.parentArtwork = selectArtworkById(this.state, this.localFormData.parentId);
		} else {
			this.parentArtwork = null;
		}
	}

	update(changedProperties) {
		super.update(changedProperties);

		// fetch new artwork data if needed, but only once
		// TODO: remove componentActive check
		if (this.updateNeeded && this.componentActive) {
			this.fetchArtworkData();
		}
	}

	// fetch artwork data from backend
	// also fetch media collection
	async fetchArtworkData() {
		this.updateNeeded = false;

		// fetch artwork from backend
		this.localFormData = null;
		await store.dispatch(fetchArtwork({id: this.artworkId}));
		await store.dispatch(clearMediaCollection());
		await store.dispatch(fetchMediaCollection({artworkId: this.artworkId}));

		// child artworks
		await store.dispatch(fetchSubArtworks({profileId: this.profileId, parentId: this.artworkId}));

		// clone data for local editing (component state)
		this.localFormData = deepClone(selectArtworkById(this.state, this.artworkId) ?? null);

		// if sub artwork, load parent data
		if (this.localFormData && this.localFormData.parentId && this.localFormData.parentId !== 0) {
			await store.dispatch(fetchArtwork({id: this.localFormData.parentId}));
		}
	}

	static get routes() {
		return getRoutes();
	}

	async router(route, params, query, data) {
		this.params = params;

		// when artwork id changes, remember that update is needed
		// update is needed when artwork id or profile id changes
		// do not update dispatch immediately to not manipulate the state here
		this.updateNeeded =
			route === 'artwork' &&
			(this.artworkId !== parseInt(params.artworkId, 10) || this.profileId !== params.id);

		// get parameters from the route: artwork id, edit mode
		this.edit = true; //params.mode === 'edit';
		this.artworkId = parseInt(params.artworkId, 10);
		this.profileId = params.id;

		this.requestUpdate();
	}

	connectedCallback() {
		super.connectedCallback();

		// fetch additional data from backend if not already loaded
		// only once, because it is not changed by this component
		if (selectAllMaterials(this.state).length === 0) {
			store.dispatch(fetchMaterials());
		}
		if (selectAllArtworkTypes(this.state).length === 0) {
			store.dispatch(fetchArtworkTypes());
		}
		if (selectAllSerie(this.state).length === 0) {
			store.dispatch(fetchSeries({profileId: this.profileId}));
		}
		// reset form changed status to allow navigation
		store.dispatch(setFormChanged(false));
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		this.componentActive = false;
		// reset form changed status to allow navigation
		store.dispatch(setFormChanged(false));
	}

	handleValueChange(fieldData, value) {
		const section = fieldData.section;
		const field = fieldData.field;
		// update localFormData with new value in section and field
		const newData = deepClone(this.localFormData); // clone current date

		if (section) {
			if (newData[section]?.[field]) {
				newData[section] = newData[section] || {};
				newData[section][field] = value; // update field
			} else {
				newData[section] = {...newData[section], [field]: value}; // create new field
			}
		} else {
			newData[field] = value; // update field
		}
		this.localFormData = newData; // assign (updates reference, triggers update)

		// when data is different, set form changed status to activate user confirmation on navigation
		store.dispatch(setFormChanged(this.formHasChanged()));
	}

	handleMeasurementChange(value) {
		const newData = deepClone(this.localFormData); // clone current date
		newData.measurements = value;
		this.localFormData = newData;

		// when data is different, set form changed status to activate user confirmation on navigation
		store.dispatch(setFormChanged(this.formHasChanged()));

		this.requestUpdate('localFormData');
	}

	handleInventoryNumbersChange(value) {
		const newData = deepClone(this.localFormData); // clone current date
		newData.inventoryNumbers = value;
		this.localFormData = newData; // assign (updates reference, triggers update)

		// when data is different, set form changed status to activate user confirmation on navigation
		store.dispatch(setFormChanged(this.formHasChanged()));

		this.requestUpdate('localFormData');
	}

	handleVisibilityChange(value) {
		const newData = deepClone(this.localFormData); // clone current date
		newData.visibility = value === 1 ? 'public' : 'private';
		this.localFormData = newData;

		// when data is different, set form changed status to activate user confirmation on navigation
		store.dispatch(setFormChanged(this.formHasChanged()));

		this.requestUpdate('localFormData');
	}

	handleStatusChange(value) {
		const newData = deepClone(this.localFormData); // clone current date
		newData.status = value === 1 ? 'published' : 'draft';
		this.localFormData = newData;

		// when data is different, set form changed status to activate user confirmation on navigation
		store.dispatch(setFormChanged(this.formHasChanged()));

		this.requestUpdate('localFormData');
	}

	handlePublicationStateChange(value) {
		const newData = deepClone(this.localFormData); // clone current date
		switch (value) {
			case 'draft':
				newData.status = 'draft';
				newData.visibility = 'private';
				break;
			case 'private':
				newData.status = 'published';
				newData.visibility = 'private';
				break;
			case 'public':
				newData.status = 'published';
				newData.visibility = 'public';
				break;
		}
		this.localFormData = newData;

		// when data is different, set form changed status to activate user confirmation on navigation
		store.dispatch(setFormChanged(this.formHasChanged()));

		this.requestUpdate('localFormData');
	}

	getPublicationStateValue() {
		if (this.localFormData.status === 'draft' && this.localFormData.visibility === 'private') {
			return 'draft';
		} else if (
			this.localFormData.status === 'published' &&
			this.localFormData.visibility === 'private'
		) {
			return 'private';
		} else if (
			this.localFormData.status === 'published' &&
			this.localFormData.visibility === 'public'
		) {
			return 'public';
		} else {
			return '';
		}
	}

	async saveArtwork() {
		await store.dispatch(updateArtwork(this.localFormData));
		// reset form changed status to allow navigation
		store.dispatch(setFormChanged(false));
	}

	cancelEditing() {
		// cancel editing and go back to preview
		store.dispatch(navigateTo({route: 'artwork', params: {...this.params, mode: 'preview'}}));
	}

	artworksList() {
		// cancel editing and go back to preview
		store.dispatch(navigateTo({route: 'artworks', params: this.params}));
	}

	goBackToEditing() {
		// go back to editing
		store.dispatch(navigateTo({route: 'artwork', params: {...this.params, mode: 'edit'}}));
	}

	addFile(file) {
		store.dispatch(createMedia({artworkId: this.artworkId, file: file}));
	}

	removeFile(fileId) {
		store.dispatch(deleteMedia({artworkId: this.artworkId, fileId: fileId}));
	}

	async handleAddChildArtwork() {
		await store.dispatch(createChildArtwork({profileId: this.profileId, parentId: this.artworkId}));
	}

	editMedia() {
		store.dispatch(navigateTo({route: 'artwork-media', params: this.params}));
	}

	formHasChanged() {
		// check if local form data is different
		const newData = deepClone(this.localFormData ?? null);
		const oldData = deepClone(selectArtworkById(this.state, this.artworkId) ?? null);
		if (newData && newData.hasOwnProperty('modified')) {
			delete newData.modified;
		}
		if (oldData && oldData.hasOwnProperty('modified')) {
			delete oldData.modified;
		}
		return !deepEqual(newData, oldData);
	}

	handleDeleteArtwork(artworkId) {
		store.dispatch(
			openDialog({
				title: msg('Remove Artwork'),
				message: msg(
					'Removed Artworks containing data are available in the Removed Artworks for 7 days after removal. Empty artworks are removed immediately.'
				),
				warningMessage: msg(
					'Removing this Artwork will immediately remove it from your list of Artworks.'
				),
				labelConfirm: msg('Remove Artwork'),
				labelCancel: msg('Cancel'),
			})
		);
		this._handleConfirm = async () => {
			const result = await store.dispatch(deleteArtwork({id: artworkId}));
			if (result.meta.requestStatus === 'fulfilled') {
				// Dispatch custom event and redirect after successful deletion
				this.dispatchEvent(
					new CustomEvent('artwork-deleted', {
						bubbles: true,
						composed: true,
						detail: {artworkId: artworkId},
					})
				);
				store.dispatch(navigateTo({route: 'artworks', params: this.params}));
			}
			window.removeEventListener('confirm', this._handleConfirm);
		};
		window.addEventListener('confirm', this._handleConfirm);
	}

	render() {
		//loading message before the form loads with data
		if (this.isLoading || !this.localFormData) {
			return html`
				<div class="loading-overlay">
					<div class="spinner"></div>
				</div>
			`;
		}

		const selectedTags = this.localFormData.tags || [];

		const basicInputFields = [
			{
				type: 'select',
				title: msg('Type of Artwork'),
				field: 'typeId',
				options: this.artworkTypesInclEmpty,
				section: null,
				multiple: false,
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'multilingualTextInput',
				title: msg('Title'),
				field: 'title',
				section: null,
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
				required: true,
			},
			{
				type: 'multilingualTextInput',
				title: msg('Subtitle'),
				field: 'subtitle',
				section: null,
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'date',
				title: msg('Artwork Date'),
				field: 'artworkDate',
				section: null,
				granularity: 'all',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
				required: true,
			},
			{
				type: 'multilingualTextInput',
				title: msg('Date Remark'),
				field: 'dateRemark',
				section: null,
				placeholder: msg('i.e. estimated, decade'),
				data: this.localFormData,
				maxLength: null,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
				required: false,
			},
			{
				type: 'checkbox',
				title: msg('Signed'),
				field: 'signed',
				section: null,
				data: this.localFormData,
				errors: this.errors,
				multilingual: false,
				required: false,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'multilingualTextInput',
				title: msg('Signature Remark'),
				field: 'signatureRemark',
				section: null,
				placeholder: msg('placeholder_signatureRemark'),
				multilingual: false,
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'multiselect',
				title: msg('Material/Technique'),
				field: 'materialIds',
				options: this.materials,
				multiple: true,
				section: null,
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
				required: true,
				showSelectedCount: true,
				showCounters: false,
			},
			{
				type: 'multilingualTextInput',
				title: msg('Additional Material Information'),
				field: 'technique',
				section: null,
				placeholder: '',
				data: this.localFormData,
				maxLength: 1000,
				multiline: true,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'multilingualTextInput',
				title: msg('Description'),
				field: 'description',
				section: null,
				placeholder: '',
				multiline: true,
				maxLength: 5000,
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'textInput',
				title: msg('Link'),
				field: 'link',
				section: null,
				placeholder: msg('placeholder_artwork_link'),
				multilingual: false,
				maxLength: null,
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'series',
				title: msg('Series'),
				field: 'seriesId',
				section: null,
				tooltip: msg('tooltip_artwork_series'),
				artworkId: this.artworkId,
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'tags',
				title: msg('Tags'),
				field: 'tags',
				section: null,
				multilingual: false,
				data: this.localFormData,
				errors: this.errors,
				tooltip: msg('tooltip_tags'),
				handler: this.handleValueChange.bind(this),
				required: false,
			},
		];

		const objectsAmountFields = [
			{
				type: 'number',
				title: msg('Objects Amount'),
				field: 'objectsAmount',
				section: null,
				errors: this.errors,
				data: this.localFormData,
				multilingual: false,
				required: false,
				handler: this.handleValueChange.bind(this),
			},
		];

		const historyStateOptions = [
			{value: '', label: msg('')},
			{value: 'undamaged', label: msg('Undamaged')},
			{value: 'damaged', label: msg('Damaged')}
		];
		const historyOwnershipOptions = [
			{value: '', label: msg('')},
			{value: 'sold', label: msg('Sold')},
			{value: 'loaned', label: msg('Loaned')},
			{value: 'unsellable', label: msg('Unsellable')},
		];
		const historyInputFields = [
			{
				type: 'select',
				title: msg('State'),
				field: 'state',
				section: 'history',
				options: historyStateOptions.map((option) => ({
					id: option.value,
					name: [{lang: 'en', value: option.label}],
				})),
				data: this.localFormData,
				errors: this.errors,
				multilingual: false,
				required: false,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'multilingualTextInput',
				title: msg('State Remarks'),
				field: 'stateText',
				section: 'history',
				maxLength: 5000,
				data: this.localFormData,
				errors: this.errors,
				multilingual: false,
				required: false,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'select',
				title: msg('Ownership'),
				field: 'ownership',
				section: 'history',
				options: historyOwnershipOptions.map((option) => ({
					id: option.value,
					name: [{lang: 'en', value: option.label}],
				})),
				data: this.localFormData,
				errors: this.errors,
				multilingual: false,
				required: false,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'textInput',
				title: msg('provenance_location'),
				field: 'location',
				section: 'history',
				maxLength: 5000,
				data: this.localFormData,
				errors: this.errors,
				multilingual: false,
				required: false,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'multilingualTextInput',
				title: msg('Provenance/Ownership Chronology'),
				field: 'provenance',
				section: 'history',
				maxLength: 5000,
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
				multilingual: true,
				required: false,
				multiline: true,
			},
			{
				type: 'multilingualTextInput',
				title: msg('Exhibition/Loan Chronology'),
				field: 'exhibitionHistory',
				section: 'history',
				maxLength: 5000,
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
				multilingual: true,
				required: false,
				multiline: true,
			},
			{
				type: 'multilingualTextInput',
				title: msg('Mentions'),
				field: 'mentions',
				section: 'history',
				data: this.localFormData,
				maxLength: 5000,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
				multilingual: true,
				required: false,
				multiline: true,
			},
			{
				type: 'multilingualTextInput',
				title: msg('Remarks'),
				field: 'remarks',
				section: 'history',
				data: this.localFormData,
				maxLength: 5000,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
				multilingual: true,
				required: false,
				multiline: true,
			},
		];

		const miscInputFields = [
			{
				type: 'multilingualTextInput',
				title: msg('Copyright/Courtesy'),
				field: 'copyright',
				section: 'misc',
				data: this.localFormData,
				maxLength: 5000,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
				multilingual: true,
				required: false,
				multiline: true,
			},
			{
				type: 'multilingualTextInput',
				title: msg('Art Handling Remarks'),
				field: 'artHandling',
				section: 'misc',
				data: this.localFormData,
				maxLength: 5000,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
				multilingual: true,
				required: false,
				multiline: true,
			},
			{
				type: 'textInput',
				title: msg('Purchasing Price'),
				field: 'purchasingPrice',
				section: 'misc',
				maxLength: 5000,
				data: this.localFormData,
				errors: this.errors,
				multilingual: false,
				required: false,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'textInput',
				title: msg('Insurance Value'),
				field: 'insuranceValue',
				section: 'misc',
				maxLength: 5000,
				data: this.localFormData,
				errors: this.errors,
				multilingual: false,
				required: false,
				handler: this.handleValueChange.bind(this),
			}
		];

		const title = this.localFormData
			? getValueForLanguage(this.localFormData.title, this.currentLanguage, true)
			: null;

		// Check if we are inside the parent artwork or child
		const isParentArtwork = !this.localFormData.parentId;

		const getTitleForLanguage = (media, language) => {
			// Check if media has a title array and it's populated
			if (media?.info?.title && Array.isArray(media.info.title)) {
				// Find the title object that matches the current language
				const titleObject = media.info.title.find((t) => t.lang === language);
				// If not found, fall back to English or the first title in the array
				return titleObject
					? titleObject.value
					: media.info.title.find((t) => t.lang === 'en')?.value ||
							media.info.title[0]?.value ||
							msg('No Title');
			}
			return msg('No Title');
		};

		return html`
			<div class="formPage">
				<arc-toolbar class="toolbar">
					<div slot="left" class="leftSlot">
						<arc-breadcrumbs>
							<arc-routerlink route="artworks" .query=${trimObject(this.artworkQuery)}>${msg('Artworks')}
							</arc-routerlink>
							${when(
								this.parentArtwork,
								() => html` <arc-routerlink
									route="artwork"
									.params=${{id: this.profileId, artworkId: this.parentArtwork.id, mode: 'edit'}}
								>
									${getValueForLanguage(this.parentArtwork.title, this.currentLanguage, true)}
								</arc-routerlink>`
							)}
							<span>${title}</span>
						</arc-breadcrumbs>
					</div>
					<div slot="right" class="rightSlot">
						<arc-button
							title="${msg('Remove Artwork')}"
							type="danger"
							class="secondary"
							?disabled="${!this.permittedToEdit}"
							@click=${() => this.handleDeleteArtwork(this.artworkId)}>
							${msg('Delete')}
						</arc-button>
						<arc-button
							title="${msg('Save Artwork')}"
							type="primary"
							.additionalInfo="${false}"
							?disabled="${!this.formChanged}"
							class="saveArtworkButton"
							@click=${this.saveArtwork}>
						</arc-button>
					</div>
				</arc-toolbar>
				<header>
					<h2 class="pageTitle">${title !== '' ? title : msg('(Untitled)')}</h2>
					<arc-content-language-switch></arc-content-language-switch>
				</header>

				<section>
					<div class="leftColumn">
						<h2 class="sectionTitle">${msg('Basic attributes')}</h2>
						<arc-form-grid
							class="formGrid basicAttributes"
							.fields=${basicInputFields}
							?disabled="${!this.permittedToEdit}"
							.localFormData=${this.localFormData}
							.errors=${this.errors}
							.edit=${this.edit}>
						</arc-form-grid>
					</div>
					<div class="rightColumn">
						<div class="visibilityInfo">
							<arc-radio
								class="formInput"
								name="publicationstate"
								title="${msg('Publication')}"
								?disabled="${!this.permittedToEdit}"
								.tooltip="${msg('tooltip_publication')}"
								.options=${this.publicationStateOptions.map((option) => ({
									id: option.value,
									name: [{lang: 'en', value: option.label}],
								}))}
								.value=${this.getPublicationStateValue()}
								@value-change=${(e) => this.handlePublicationStateChange(e.detail.value)}
							></arc-radio>
						</div>
						<div class="mediaSection">
							<h2 class="sectionTitle">${msg('Media')}</h2>
							<div class="artworkMedia">
								${repeat(
									this.mediaCollection,
									(media) => media.id,
									(media) => html`
										${when(
											media.ext === 'jpg' || media.ext === 'jpeg' || media.ext === 'png',
											() => html`
												<div class="mediaThumbnail">
													<arc-image .file=${media} alt="Main Image"></arc-image>
												</div>
											`
										)}

										<!-- PDF Thumbnail -->
										${when(
											media.ext === 'pdf',
											() => html`
												<div class="mediaThumbnail">
													<a href="${media.url}" target="_blank" class="pdfThumbnailLink">
														<span class="pdfIcon">${viewPdf}</span>
														<p class="mediaTitle">
															${getTitleForLanguage(media, this.currentLanguage)}
														</p>
													</a>
												</div>
											`
										)}

										<!-- Video Thumbnail -->
										${when(
											media.ext === 'mp4',
											() => html`
												<div class="mediaThumbnail">
													<a href="${media.url}" target="_blank" class="videoThumbnailLink">
														<span class="videoIcon">${viewVideo}</span>
														<p class="mediaTitle">
															${getTitleForLanguage(media, this.currentLanguage)}
														</p>
													</a>
												</div>
											`
										)}
										<!-- Audio Thumbnail -->
										${when(
											media.ext === 'mp3' || media.ext === 'wav' || media.ext === 'ogg',
											() => html`
												<div class="mediaThumbnail">
													<a href="${media.url}" target="_blank" class="audioThumbnailLink">
														<span class="audioIcon">${volume}</span>
														<!-- You can replace this with your audio icon -->
														<p class="mediaTitle">
															${getTitleForLanguage(media, this.currentLanguage)}
														</p>
													</a>
												</div>
											`
										)}
									`
								)}
							</div>
							<div class="editMediaButtonContainer">
								<arc-routerlink
									route="artwork-media"
									buttonStyle
									.params=${{language: this.status.lang, id: this.profileId, artworkId: this.artworkId}}
									noUnderline
									buttonStyle
								>
									${msg('Edit Media')}
								</arc-routerlink>
							</div>
						</div>
					</div>

				</section>

				<section>
					<div class="leftColumn">
						<h2 class="sectionTitle">${msg('Inventory Numbers')} <arc-tooltip text="${msg('tooltip_inventory_numbers')}"></arc-tooltip></h2>

						<div class="keyValueList">
							<div class="keyValue">
								<div class="key">${getTenantData(this.tenant, 'name')} ${msg('Inventory Number')}</div>
								<div class="value">
									${this.localFormData.inventoryNumber}
									<arc-tooltip text="${msg('tooltip_kunstfonds-number')}"></arc-tooltip>
								</div>
							</div>
						</div>

						<arc-form-repeat
							.components=${[
								{
									component: 'arc-inventory-number',
									type: null,
									label: msg('Additional Inventory Number'),
								},
							]}
							?disabled="${!this.permittedToEdit}"
							.data=${this.localFormData.inventoryNumbers}
							.errors=${this.errors?.params?.inventoryNumbers.params}
							@value-change=${(e) => this.handleInventoryNumbersChange(e.detail.value)}
						></arc-form-repeat>

					</div>
					<div class="rightColumn"></div>
				</section>

				<section>
					<div class="leftColumn">
						<arc-collapsible-section title="${msg('Measurements')}">
							<arc-form-repeat
								.components=${[
									{component: 'arc-space', type: 'space', label: msg('Space')},
									{component: 'arc-diameter', type: 'diameter', label: msg('Diameter')},
									{component: 'arc-weight', type: 'weight', label: msg('Weight')},
									{component: 'arc-diagonal', type: 'diagonal', label: msg('Diagonal')},
									{component: 'arc-resolution', type: 'resolution', label: msg('Resolution')},
									{component: 'arc-duration', type: 'duration', label: msg('Duration')},
								]}
								?disabled="${!this.permittedToEdit}"
								.data=${this.localFormData.measurements}
								.errors=${this.errors?.params?.measurements.params}
								@value-change=${(e) => this.handleMeasurementChange(e.detail.value)}
							>
							</arc-form-repeat>
						</arc-collapsible-section>

					</div>
					<div class="rightColumn"></div>
				</section>

				${when(
					isParentArtwork,
					() => html`
						<section>
							<div class="fullColumn">
								<h2 class="sectionTitle">${msg('Sub Artworks')}</h2>
								${repeat(
						this.childArtworks,
						(artwork) => artwork.id,
									(artwork) => html`
										<arc-artwork-list-item
											.artwork=${artwork}
											.currentLanguage=${this.currentLanguage}
										></arc-artwork-list-item>
									`
								)}
								<arc-button
									title="${msg('Add Child Artwork')}"
									type="primary"
									.additionalInfo="${false}"
									class="addChildArtworkButton"
									showIcon="true"
									?disabled="${!this.permittedToEdit}"
									@click=${this.handleAddChildArtwork}
								>
									${plus}
								</arc-button>
							</div>
						</section>
					`
				)}

				<section>
					<div class="leftColumn">
						<arc-collapsible-section title="${msg('Provenance')}">
							<arc-form-grid
								class="formGrid historyAttributes"
								.fields=${historyInputFields}
								.errors=${this.errors}
								?disabled="${!this.permittedToEdit}"
							>
							</arc-form-grid>
						</arc-collapsible-section>
					</div>
					<div class="rightColumn"></div>
				</section>

				<section>
					<div class="leftColumn">
						<arc-collapsible-section title="${msg('Miscellaneous')}">
							<arc-form-grid
								class="formGrid miscAttributes"
								.fields=${miscInputFields}
								.errors=${this.errors}
								?disabled="${!this.permittedToEdit}"
							>
							</arc-form-grid>
						</arc-collapsible-section>
					</div>
					<div class="rightColumn"></div>
				</section>

				<section>
					<div class="fullColumn">
						<arc-collapsible-section title="${msg('Changelog')}">
							<arc-activity-list .showFilter="${false}" .artworkId="${this.artworkId}"></arc-activity-list>
						</arc-collapsible-section>
					</div>
				</section>

			</div>
			</div>
		`;
	}
}
