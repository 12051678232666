import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {navigator, router} from 'lit-element-router';
import {customElement} from 'lit/decorators/custom-element.js';
import {repeat} from 'lit/directives/repeat.js';
import {msg} from '@lit/localize';
import deepEqual from 'deep-equal';

import store from '../../store';
import style from '../../../../scss/activityList.scss';
import {
	selectActivities,
	selectLanguage, selectLogsQuery
} from '../../selectors';
import getRoutes from '../../routes';
import down from '../../icons/down';
import up from '../../icons/up';
import {
	fetchActivities, fetchLogs, selectAllLogs, selectTotalLogs
} from '../../slices/activitySlice';

@customElement('arc-activity-list')
export class ActivityList extends connect(store)(router(navigator(LitElement))) {
	static styles = [style];

	static properties = {
		showFilter: {type: Boolean},
		artworkId: {type: Number},
	};

	constructor() {
		super();
		this.currentLanguage = 'de';
		this.activities = [];
		this.logs = [];
		this.listType = 'all';
		this.showFilter = true;
		store.subscribe(() => this.requestUpdate());
		this.pageSize = 50;
	}

	stateChanged(state) {
		this.state = state;
		this.status = state.status;
		this.lang = selectLanguage(state);
		this.logs = selectAllLogs(state);
		this.activities = selectActivities(state).map(activity => ({
			id: activity, name: [{lang: 'en', value: activity}, {lang: 'de', value: activity}]
		}));
		this.logsQuery = selectLogsQuery(state);
		this.totalPages = Math.ceil(selectTotalLogs(state) / this.pageSize);
		if (this.updateNeeded) {
			this.updateNeeded = false;
			store.dispatch(fetchLogs({...this.query, artworkId: this.artworkId}));
		}
		this.requestUpdate();
	}

	static get routes() {
		return getRoutes();
	}

	router(route, params, query, data) {

		// do activities need update from backend
		this.updateNeeded = (route === 'admin-activities' || route === 'artwork') &&
			(!deepEqual(this.query, query) || this.artworkId !== params.id);

		this.profileId = params.id;
		this.params = params;
		this.query = query;
		this.data = data;
	}

	connectedCallback() {
		super.connectedCallback();
		if (this.showFilter) {
			store.dispatch(fetchActivities({...this.query}));
		}
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	getOrderQuery(orderBy) {
		return {
			...this.query,
			...{
				orderBy: orderBy,
				orderByDirection: this.logsQuery.orderByDirection === 'desc' ? 'asc' : 'desc'
			}
		};
	}

	getOrderDirectionIcon(orderBy) {
		if (this.query.orderBy === orderBy) {
			return this.logsQuery.orderByDirection === 'asc' ? down : up;
		} else {
			return '';
		}
	}

	render() {
		return html`
			<arc-activity-list-filter .showFilter="${this.showFilter}"></arc-activity-list-filter>
			<div class="activityList">
				${this.logs.length > 0
					? html`
						<div class="activityListHeader">
							<div>${msg('Time')}</div>
							<div>${msg('Activity')}</div>
							<div>${msg('Artwork')}</div>
							<div>${msg('Series')}</div>
							<div>${msg('Account')}</div>
							<div>${msg('Profile')}</div>
						</div>

						${repeat(
							this.logs,
							(log) => log.id,
							(log, index) => html`
								<div class="activityListItem">
									<arc-activity-list-item .log=${log}></arc-activity-list-item>
								</div>`
						)}
						<arc-pagination .totalPages=${this.totalPages}></arc-pagination>
					`
					: html` <p class="emptyMessage">${msg('No activities.')}</p> `}
			</div>
		`;
	}
}
