import {html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {classMap} from 'lit/directives/class-map.js';
import {when} from 'lit/directives/when.js';
import {msg} from '@lit/localize';

import style from '../../../../../scss/multiselect.scss';
import {FormElement} from './formElement';

import down from '../../../icons/down';
import up from '../../../icons/up';
import check from '../../../icons/check';

@customElement('arc-multiselect')
export class Multiselect extends FormElement {
	static properties = {
		...FormElement.properties,
		options: {type: Array},
		value: {type: Array},
		title: {type: String},
		isDropdownOpen: {type: Boolean},
		searchTerm: {type: String},
		multiple: {type: Boolean},
		singleSelect: {type: Boolean},
		labelField: {type: String},
		valueField: {type: String},
		required: {type: Boolean},
		showCounters: {type: Boolean},
		showSelectedCount: {type: Boolean},
		emptyOption: {type: Object},
	};

	static styles = [...FormElement.styles, style];

	constructor() {
		super();
		this.options = [];
		this.value = null;
		this.isDropdownOpen = false;
		this.searchTerm = '';
		this.multiple = true;
		this.singleSelect = false;
		this.labelField = 'name';
		this.disabled = false;
		this.valueField = 'id';
		this.showCounters = false;
		this.showSelectedCount = false;
	}

	stateChanged(state) {
		super.stateChanged(state);
		// convert string like [id1, id2] to actual array of ids
		if (typeof this.value === 'string') {
			this.value = JSON.parse(this.value);
		}
	}
	connectedCallback() {
		super.connectedCallback();
		document.addEventListener('click', this.handleClickOutside);
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		document.removeEventListener('click', this.handleClickOutside);
	}

	handleClickOutside = (event) => {
		const path = event.composedPath();
		if (this.isDropdownOpen && !path.includes(this)) {
			this.isDropdownOpen = false;
			this.searchTerm = '';
		}
	};

	toggleDropdown = () => {
		this.isDropdownOpen = !this.isDropdownOpen;
		if (this.isDropdownOpen) {
			this.requestUpdate();
			setTimeout(() => {
				const inputField = this.renderRoot.querySelector('input[type="text"]');
				if (inputField) {
					inputField.focus();
				}
			}, 0);
		} else {
			this.searchTerm = ''; // when closing dropdown, reset search
		}
	};

	handleSearchInput = (e) => {
		this.searchTerm = e.target.value.toLowerCase();
	};

	handleOptionClick = (optionId) => {
		if (optionId === null) {
			this.value = [];
			this.isDropdownOpen = false;
		} else if (this.singleSelect) {
			this.value = [optionId];
			this.isDropdownOpen = false;
		} else {
			if (this.value.includes(optionId)) {
				this.value = this.value.filter((id) => id !== optionId);
			} else {
				this.value = [...this.value, optionId];
			}
		}
		this.dispatchValueChange(this.value.length === 0 ? [] : this.value);
	};

	isSelected(optionId) {
		return this.value?.includes(optionId);
	}

	getStringForCurrentLanguage(value) {
		if (!value || value.length === 0) return '';
		const str = value.find((v) => v.lang === this.lang);
		return str ? str.value + '' : '';
	}

	getOptionLabel(option) {
		if (!option || !Array.isArray(option[this.labelField])) {
			return '[Unknown]';
		}
		return this.getStringForCurrentLanguage(option[this.labelField]);
	}

	get filteredOptions() {
		if (!this.searchTerm) {
			return this.options;
		}

		const searchTermLowerCase = this.searchTerm.toLowerCase();

		return this.options.filter((option) => {
			const label = this.getOptionLabel(option);
			return label.toLowerCase().includes(searchTermLowerCase);
		});
	}

	getSelectedOptionsSummary() {
		const selectedLabels = this.value
			.map((id) => {
				const option = this.options.find((opt) => opt[this.valueField] + '' === id + '');
				return option ? this.getOptionLabel(option) : '';
			})
			.filter((label) => label)
			.slice(0, 3); // Limit to the first 3 items

		if (this.value.length > 3) {
			selectedLabels.push('...');
		}

		return selectedLabels.join(', ');
	}

	getSelectedCount() {
		return this.value.length;
	}

	renderOption(option) {
		const label = this.getOptionLabel(option);

		const isDisabled = this.showCounters && option.counter === 0;
		const count = this.showCounters && option.counter !== undefined ? html` <span class="counter">${option.counter}</span>` : '';
		return html`
			<li
				class="optionItem ${classMap({
					selected: this.isSelected(option[this.valueField]),
					disabled: isDisabled,
				})}"
				@click=${() => !isDisabled && this.handleOptionClick(option[this.valueField])}
			>
				<span class="checkmark">
					${this.isSelected(option[this.valueField]) ? html`<span>${check}</span>` : ''}
				</span>
				${label}${count}
			</li>
		`;
	}

	render() {
		return html`
			<div class="formField ${classMap({errors: this.hasErrors(), changed: this.inputHasChanged(), disabled: this.disabled})}">
				<div class="multiselectInputInfo">
					<div class="textInputInfo">
						<div class="title">
							${this.title} ${when(this.required, () => html`<span class="requiredMark">*</span>`)}
						</div>
						${when(this.tooltip, () => html`<arc-tooltip text=${this.tooltip}></arc-tooltip>`)}
					</div>
				</div>
				<div class="${classMap({'dropdown-container': true, open: this.isDropdownOpen})}">
					<div class="selectHeader" @click=${this.toggleDropdown}>
						${when(
							!this.isDropdownOpen,
							() => html`
								<span>
									${this.value?.length
										? this.getSelectedOptionsSummary()
										: this.emptyOption
										? this.emptyOption.label
										: msg('Select options') + ' ...'}
								</span>
								${when(
									this.showSelectedCount && this.value?.length > 3,
									() => html` <span class="selectedCount">+ ${this.getSelectedCount() - 3}</span> `
								)}
							`,
							() => html`
								<input
									type="text"
									placeholder="${msg('Search')} ..."
									.value=${this.searchTerm}
									?disabled="${this.disabled}"
									@input=${this.handleSearchInput}
									@click=${(e) => e.stopPropagation()}
								/>
							`
						)}
						<span class="dropdownArrow">
							${this.isDropdownOpen ? html`<span>${up}</span>` : html`<span>${down}</span>`}
						</span>
					</div>

					${when(
						this.isDropdownOpen,
						() => html`
							<ul class="optionsList">
								${when(
									this.emptyOption,
									() => html`
										<li class="optionItem" @click=${() => this.handleOptionClick(null)}>
											${this.emptyOption.label}
										</li>
									`
								)}
								${this.filteredOptions.map((option) => this.renderOption(option))}
							</ul>
						`
					)}
				</div>
				${when(this.hasErrors(), () => html`<arc-errors .errors=${this.errors}></arc-errors>`)}
			</div>
		`;
	}
}
