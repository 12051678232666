import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {msg} from '@lit/localize';
import {when} from 'lit/directives/when.js';

import store from '../../store';

import {exportData, logout} from '../../slices/accountSlice';

import style from '../../../../scss/accountView.scss';
import formPageStyle from '../../../../scss/formPage.scss';
import keyValueListStyle from '../../../../scss/keyValueList.scss';
import {selectCurrentAccount, selectLoggedIn} from '../../selectors';

@customElement('arc-account-view')
export class AccountView extends connect(store)(LitElement) {
	static styles = [keyValueListStyle, formPageStyle, style];

	constructor() {
		super();
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.status = state.status;
		this.exportRequested = state.account.data.exportRequested;
		this.accountData = selectCurrentAccount(state);
		this.loggedIn = selectLoggedIn(state);
	}

	async connectedCallback() {
		super.connectedCallback();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	onLogout(input) {
		store.dispatch(logout());
	}
	onExport(input) {
		store.dispatch(exportData());
	}
	render() {
		const state = store.getState();
		return html`
			<div class="formpage account-view">
				<arc-toolbar class="toolbar">
					<div slot="left">
						<arc-breadcrumbs>
							<arc-routerlink route="account">${msg('Account')}: ${this.accountData.email}</arc-routerlink>
						</arc-breadcrumbs>
					</div>
					<div slot="right">
						<arc-button
							@click=${this.onLogout}
							title="${msg('Logout')}"
							type="primary"
							.additionalInfo="${false}">
						</arc-button>
					</div>
				</arc-toolbar>

				<header>
					<h2 class="pageTitle">${msg('Account')}</h2>
				</header>

				<section>
					<div class="fullColumn">
						<h2>${msg('Details')}</h2>
						<div class="keyValueList">
							<div class="keyValue">
								<div class="key">${msg('Name')}</div>
								<div class="value">${this.accountData.name}</div>
							</div>
							<div class="keyValue">
								<div class="key">${msg('Email')}</div>
								<div class="value">${this.accountData.email}</div>
							</div>
							<div class="keyValue">
								<div class="key">${msg('Role')}</div>
								<div class="value">${this.accountData.role}</div>
							</div>
							${when(this.accountData.invitedBy !== 0, () => html`
								<div class="keyValue">
									<div class="key">${msg('invited by')}</div>
									<div class="value">${this.accountData.invitedByName}</div>
								</div>`
							)}
							<div class="keyValue">
								<div class="key">${msg('ID')}</div>
								<div class="value">${this.accountData.id}</div>
							</div>
						</div>
					</div>
				</section>
				<section>
					<div class="fullColumn">
						<h2>${msg('Export')}</h2>
						<arc-button
							@click=${this.onExport}
							title="${msg('Export Data')}"
							type="primary"
							?disabled=${this.exportRequested}
							.additionalInfo="${false}">
						</arc-button>
						<p>${msg('export_info')}</p>
					</div>
				</section>
				<section>
					<div class="fullColumn">
						<h2>${msg('Select Profile to edit')}</h2>
						<p>${msg('description_select_profile')}</p>
						<arc-profile-list></arc-profile-list>
					</div>
				</section>

				<section>
					<div class="fullColumn">
						<h2>${msg('Invited Editors')}</h2>
						<p>${msg('description_invited_accounts')}</p>
						<arc-invited-list .showSearch="${false}" .showFilter="${false}"></arc-invited-list>
					</div>
				</section>

			</div>
		`;
	}
}
