import {createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit';
import agent from '../../arc/agent/agent';

export const initialLogsQuery = {
	orderBy: 'created',
	orderByDirection: 'desc', // 'asc', 'desc'
	page: 1,
};


export const fetchActivities = createAsyncThunk('activity/fetchActivities', (options, {getState}) =>
	agent
		.get(`${getState().status.apiUrl}/activity/getActivities`)
		.set('Accept', 'application/json')
		.then((response) => response.body)
		.catch((error) => error));

export const fetchLogs = createAsyncThunk('activity/fetchLogs', (options, {getState}) => {
	const query = {...initialLogsQuery, ...options};
	if (query.activities) {
		query.activities = JSON.stringify([query.activities]);
	}
	return agent
		.get(`${getState().status.apiUrl}/activity/logs`)
		.query(query ?? initialLogsQuery)
		.set('Accept', 'application/json')
		.then((response) => response.body)
		.catch((error) => error);
	}
);

export const logsAdapter = createEntityAdapter();

export const activitySlice = createSlice({
	name: 'activity',
	initialState: {
		status: 'idle',
		activities: [],
		query: initialLogsQuery,
		logs: logsAdapter.getInitialState(),
		logsPagination: {
			amount: 0,
			totalAmount: 0,
			pagesAmount: 1,
			page: 1,
		},
		error: {},
	},

	// standard actions: for example update data
	// export actions at end of file
	reducers: {
		setLogsQuery: (state, action) => {
			// merge payload with current query
			state.query = action.payload;
		},
		clear: (state) => {
			logsAdapter.removeAll(state.logs);
		},
	},

	// async actions: server api calls workflows
	extraReducers: (builder) => {
		builder.addCase(fetchActivities.pending, (state, action) => {
			state.error = null;
		});
		builder.addCase(fetchActivities.fulfilled, (state, action) => {
			state.error = null;
			state.activities = action.payload.items;
		});
		builder.addCase(fetchActivities.rejected, (state, action) => {
			state.loading = 'idle';
			state.error = action.error.message;
		});

		builder.addCase(fetchLogs.pending, (state, action) => {
			state.loading = 'loading';
			state.error = null;
		});
		builder.addCase(fetchLogs.fulfilled, (state, action) => {
			state.loading = 'idle';
			state.error = null;
			logsAdapter.removeAll(state.logs);
			logsAdapter.upsertMany(state.logs, action.payload.collection.items);
		});
		builder.addCase(fetchLogs.rejected, (state, action) => {
			state.loading = 'idle';
			state.error = action.error.message;
		});
	},
});

export const {
	selectById: selectLogsById,
	selectIds: selectLogsIds,
	selectEntities: selectLogsEntities,
	selectAll: selectAllLogs,
	selectTotal: selectTotalLogs,
} = logsAdapter.getSelectors((state) => state.activity.logs);


export const {clear, setLogsQuery} =
	activitySlice.actions;

export default activitySlice.reducer;
