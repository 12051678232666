export const tenants = {
	arc: {
		logo: '/assets/tenants/arc/logo.svg',
		welcome: '/assets/tenants/arc/welcome.jpg',
		name:	'CDLX',
		productname: 'Werkverzeichnis'
	},
	kfn: {
		logo: '/assets/tenants/kfn/logo.svg',
		welcome: '/assets/tenants/kfn/welcome.jpg',
		name:	'Stiftung Kunstfonds',
		productname: 'Werkverzeichnung'
	},
	ksa: {
		logo: '/assets/tenants/ksa/logo.svg',
		welcome: '/assets/tenants/ksa/welcome.jpg',
		name:	'Kunststiftung Sachsen-Anhalt',
		productname:	'Werkverzeichnis',
	},
};

export function getTenantData(tenant, field) {
	return tenants[tenant][field];
}
