function getRoutes() {
	// TODO: set base from environment
	const base = '/';
	return [
		{
			name: 'start',
			pattern: '',
			data: {title: 'Home'},
		},
		{
			name: 'home',
			pattern: base + ':language',
			data: {title: 'Home'},
		},
		{
			name: 'account',
			pattern: base + ':language/account',
			data: {title: 'Account'},
		},
		{
			name: 'login',
			pattern: base + ':language/login',
			data: {title: 'Login'},
		},
		{
			name: 'artist',
			pattern: base + ':language/artist/:id',
			data: {title: 'Artist'},
		},
		{
			name: 'artist-cv',
			pattern: base + ':language/artist/:id/cv',
			data: {title: 'CV Artist'},
		},
		{
			name: 'artist-settings',
			pattern: base + ':language/artist/:id/settings',
			data: {title: 'Artist Settings'},
		},
		{
			name: 'artist-share',
			pattern: base + ':language/artist/:id/share',
			data: {title: 'Artist Share'},
		},
		{
			name: 'artist-profiles',
			pattern: base + ':language/artist/:id/profiles',
			data: {title: 'Profile List'},
		},
		{
			name: 'artworks',
			pattern: base + ':language/artist/:id/artworks',
			data: {title: 'Artworks'},
		},
		{
			name: 'artworks-bin',
			pattern: base + ':language/artist/:id/artworks/bin',
			data: {title: 'Deleted Artworks'},
		},
		{
			name: 'artwork',
			pattern: base + ':language/artist/:id/artwork/:artworkId',
			data: {title: 'Artwork'},
		},
		{
			name: 'artwork-media',
			pattern: base + ':language/artist/:id/artwork/:artworkId/media',
			data: {title: 'Artwork Media'},
		},
		{
			name: 'series-list',
			pattern: base + ':language/artist/:id/series',
			data: {title: 'Artwork Series List'},
		},
		{
			name: 'series-create',
			pattern: base + ':language/artist/:id/series/create-series',
			data: {title: 'Create Series'},
		},
		{
			name: 'series-detail',
			pattern: base + ':language/artist/:id/serie/:seriesId',
			data: {title: 'Artwork Series'},
		},
		{
			name: 'admin-accounts',
			pattern: base + ':language/admin/accounts',
			data: {title: 'Accounts'},
		},
		{
			name: 'admin-account-create',
			pattern: base + ':language/admin/accounts/create-account',
			data: {title: 'Create Account'},
		},
		{
			name: 'admin-account',
			pattern: base + ':language/admin/account/:accountId',
			data: {title: 'Edit Account'},
		},
		{
			name: 'admin-activities',
			pattern: base + ':language/admin/activities',
			data: {title: 'Activity Logs'},
		},
		{
			name: 'not-found',
			pattern: '*',
		},
	];
}

export default getRoutes;
