import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {navigator, router} from 'lit-element-router';
import {customElement} from 'lit/decorators/custom-element.js';
import {repeat} from 'lit/directives/repeat.js';
import {msg} from '@lit/localize';
import deepEqual from 'deep-equal';

import store from '../../store';
import style from '../../../../scss/artworkList.scss';
import {
	fetchArtworks,
	selectAllArtwork,
	selectAllMaterials,
	selectAllArtworkTypes,
	selectAllMedia,
} from '../../slices/artworkSlice';
import {getValueForLanguage} from '../../util/getValueForLanguage';
import {
	selectArtworkQuery,
	selectProfileDefaultLanguage,
	selectLanguage,
} from '../../selectors';
import getRoutes from '../../routes';
import arrowRight from '../../icons/arrowRight';
import arrowDown from '../../icons/arrowDown';
import arrowUp from '../../icons/arrowUp';
import down from '../../icons/down';
import up from '../../icons/up';
import {isPermitted} from '../../util/isPermitted';

@customElement('arc-artwork-list')
export class ArtworkList extends connect(store)(router(navigator(LitElement))) {
	static styles = [style];

	static properties = {
		materials: {type: Array},
		isMobile: {type: Boolean},
		searchText: {type: String},
		filtersVisible: {type: Boolean},
		noFilters: {type: Boolean},
		contextClass: {type: String},

		manualSortOrder: {type: Array},
		updateNeeded: {type: Boolean}
	};

	constructor() {
		super();
		this.currentLanguage = 'de';
		this.artworks = [];
		this.isMobile = window.innerWidth <= 768;
		this.searchText = '';
		this.filtersVisible = false;
		this.manualSortOrder = [];
		this.seriesId = null;
		this.updateNeeded = false;
		this.componentActive = true;
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.status = state.status;
		this.lang = selectLanguage(state);
		this.artworks = selectAllArtwork(state);
		this.materials = selectAllMaterials(state);
		this.artworkTypes = selectAllArtworkTypes(state);
		this.mediaCollections = selectAllMedia(state);
		this.artworkQuery = selectArtworkQuery(state);
		this.totalPages = state.artwork.artworkPagination.pagesAmount;

		this.permittedToView = isPermitted(['artwork/view'], this.profileId, state);
		this.permittedToEdit = isPermitted(['artwork/edit'], this.profileId, state);

		this.currentLanguage = selectProfileDefaultLanguage(state);
	}
	update(changedProperties) {
		super.update(changedProperties);

		// fetch new artwork data if needed, but only once							<arc-pagination .totalPages=${this.totalPages}></arc-pagination>
		if (this.updateNeeded && this.componentActive) {
			this.fetchArtworks();
		}
	}

	fetchArtworks() {
		this.updateNeeded = false;
		store.dispatch(
			fetchArtworks({
				profileId: this.profileId,
				seriesId: this.seriesId,
				isDeleted: this.isBin,
				...this.query,
				...{withCounters: true}
			}));
	}

	static get routes() {
		return getRoutes();
	}

	async router(route, params, query, data) {

		// do artworks need update from backend
		this.updateNeeded =
			this.route !== route ||
			(route === 'artworks' && (this.profileId !== params.id || !deepEqual(this.query, query))) ||
			(route === 'artworks-bin' && (this.profileId !== params.id || !deepEqual(this.query, query))) ||
			(route === 'series-detail' && this.seriesId !== params.seriesId);

		this.route = route;
		this.params = params;
		this.query = query;
		this.data = data;

		this.profileId = params.id;
		this.seriesId = params.seriesId;
		this.isBin = route === 'artworks-bin';

		this.requestUpdate();
	}

	connectedCallback() {
		super.connectedCallback();
		this.addEventListener('artwork-deleted', this._refreshArtworkList.bind(this));
		window.addEventListener('resize', this._handleResize.bind(this));
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		this.componentActive = false;
		this.removeEventListener('artwork-deleted', this._refreshArtworkList.bind(this));
		window.removeEventListener('resize', this._handleResize.bind(this));
	}

	_refreshArtworkList() {
		store.dispatch(fetchArtworks({profileId: this.profileId, ...this.query}));
	}

	_handleResize() {
		this.isMobile = window.innerWidth <= 600;
	}

	updateSearch(event) {
		this.searchText = event.target.value.trim().toLowerCase();
	}

	toggleFilters() {
		this.filtersVisible = !this.filtersVisible;
	}
	getOrderQuery(orderBy) {
		return {
			...this.query,
			...{
				orderBy: orderBy,
				orderByDirection: this.artworkQuery.orderByDirection === 'desc' ? 'asc' : 'desc',
			},
		};
	}
	getOrderDirectionIcon(orderBy) {
		if (this.query.orderBy === orderBy) {
			return this.artworkQuery.orderByDirection === 'asc' ? down : up;
		} else {
			return '';
		}
	}

	moveArtwork(index, direction) {
		if (direction === 'up' && index > 0) {
			[this.artworks[index - 1], this.artworks[index]] = [
				this.artworks[index],
				this.artworks[index - 1],
			];
		}

		if (direction === 'down' && index < this.artworks.length - 1) {
			[this.artworks[index + 1], this.artworks[index]] = [
				this.artworks[index],
				this.artworks[index + 1],
			];
		}
		this.requestUpdate();

		const updatedArtworkIds = this.artworks.map((artwork) => artwork.id);
		// dispatch changed order to series-detail, so it saves
		this.dispatchEvent(
			new CustomEvent('artwork-order-changed', {
				detail: {artworkIds: updatedArtworkIds},
				bubbles: true,
				composed: true,
			})
		);
	}

	render() {
		// if al data is available, sort this.artworks according to manualSortOrder
		if (this.artworks && this.artworks.length > 0 && this.manualSortOrder && this.manualSortOrder.length > 0) {
			this.artworks = this.manualSortOrder
				.map((id) => this.artworks.find((artwork) => artwork.id === id))
				.filter((artwork) => artwork); // filter not available artworks,i.e. deleted
		}
		return html`
			${!this.noFilters ? html`<arc-artwork-list-filter></arc-artwork-list-filter>` : ''}

			<div class="artworkList">
				${this.artworks.length > 0
					? html`
							${this.isMobile
								? html`
										${repeat(
											this.artworks,
											(artwork) => artwork.id,
											(artwork) => {
												const imageUrl = artwork.firstImage?.url || '';
												const artworkTitle =
													getValueForLanguage(artwork.title, this.currentLanguage, true) ||
													'Untitled';
												return html`
													<div class="artworkListItem">
														<div class="artworkInfo">
															<arc-image
																class="artworkImage"
																.file=${artwork.firstImage ?? null}
																alt="${getValueForLanguage(
																	artwork.firstImage?.info?.alt,
																	this.currentLanguage,
																	true
																)}"
															>
															</arc-image>
															<div>${artworkTitle}</div>
														</div>
														<arc-routerlink
															route="artwork"
															noUnderline
															boldStyle
															.params=${{id: artwork.id, mode: 'edit'}}
														>
															${msg('Edit')} ${arrowRight}
														</arc-routerlink>
													</div>
												`;
											}
										)}
								  `
								: html`
										<div class="artworkListHeader">
											<div></div>
											<div>
												<arc-routerlink
													.route="artworks"
													.query=${this.getOrderQuery('inventoryNumber')}
												>
													${msg('Nr.')} ${this.getOrderDirectionIcon('inventoryNumber')}
												</arc-routerlink>
											</div>
											<div>${msg('Title')}</div>
											<div>${msg('Materials')}</div>
											<div>
												<arc-routerlink
													.route="artworks"
													.query=${this.getOrderQuery('artworkDate')}
												>
													${msg('Year')} ${this.getOrderDirectionIcon('artworkDate')}
												</arc-routerlink>
											</div>
											<div>
													${msg('Publication')}
											</div>
										</div>

										${repeat(
											this.artworks,
											(artwork) => artwork.id,
											(artwork, index) => html`
												<div class="artworkListItem">
													${this.seriesId
														? html`
																<div class="arrowControls">
																	<button
																		?disabled="${index === 0}"
																		class="${index === 0 ? 'disabledArrow' : ''}"
																		@click=${() => this.moveArtwork(index, 'up')}
																	>
																		${arrowUp}
																	</button>

																	<button
																		?disabled="${index === this.artworks.length - 1}"
																		class="${index === this.artworks.length - 1
																			? 'disabledArrow'
																			: ''}"
																		@click=${() => this.moveArtwork(index, 'down')}
																	>
																		${arrowDown}
																	</button>
																</div>
														  `
														: ''}
													<arc-artwork-list-item
														.artwork=${artwork}
														.currentLanguage=${this.currentLanguage}
														.seriesContext=${this.seriesContext}
													></arc-artwork-list-item>
												</div>
											`
										)}
								  `}
							<arc-pagination .totalPages=${this.totalPages}></arc-pagination>
					  `
					: html` <p class="emptyMessage">${msg('No artworks found.')}</p> `}
			</div>
		`;
	}
}
