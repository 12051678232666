import {LitElement, html, css} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {navigator, router} from 'lit-element-router';
import {msg, str} from '@lit/localize';
import {connect} from 'pwa-helpers';

import style from '../../../../scss/activityListItem.scss';
import store from '../../store';
import arrowRight from '../../icons/arrowRight';
import {openDialog} from '../../slices/statusSlice';

@customElement('arc-activity-list-item')
export class ActivityListItem extends connect(store)(navigator(LitElement)) {
	static styles = [style];

	static properties = {
		log: {type: Object},
		currentLanguage: {type: String},
		customClass: {type: String},
		isDropdownOpen: {type: Boolean},
	};

	constructor() {
		super();
		this.isDropdownOpen = false;
		store.subscribe(() => this.requestUpdate());
	}

	connectedCallback() {
		super.connectedCallback();
	}

	toggleDropdown() {
		this.isDropdownOpen = !this.isDropdownOpen;
	}

	closeDropdown() {
		this.isDropdownOpen = false;
	}

	render() {
		const classes = this.customClass ? `activityItem ${this.customClass}` : 'activityItem';
		return html`
			<div class="${classes}">
				<div class="cell">
					${this.log.created}
				</div>
				<div class="cell">
					${this.log.activityName}
				</div>
				<div class="cell">
					${this.log.artworkTitle}
				</div>
				<div class="cell">
					${this.log.seriesTitle}
				</div>
				<div class="cell">
					${this.log.accountName}
				</div>
				<div class="cell">
					${this.log.profileName}
				</div>
			</div>
    `;
	}
}
