import {connect} from 'pwa-helpers';
import deepEqual from 'deep-equal';
import {LitElement, html} from 'lit';
import {navigator, router} from 'lit-element-router';
import {customElement} from 'lit/decorators/custom-element.js';
import {when} from 'lit/directives/when.js';

import {msg} from '@lit/localize';
import store from '../../store';
import style from '../../../../scss/activityListFilter.scss';
import {
	initialLogsQuery, selectAllLogs,
	setLogsQuery
} from '../../slices/activitySlice';

import {
	selectProfileDefaultLanguage,
	selectLanguage, selectLogsQuery, selectActivities
} from '../../selectors';
import {routeUrl} from '../../util/routeUrl';
import getRoutes from '../../routes';
import {trimObject} from '../../util/trimObject';

@customElement('arc-activity-list-filter')
export class ActivityListFilter extends connect(store)(router(navigator(LitElement))) {
	static styles = [style];

	static properties = {
		activities: {type: Array},
		showFilter: {type: Boolean},
	};

	constructor() {
		super();
		this.currentLanguage = 'en';
		this.showFilter = true;
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.status = state.status;
		this.lang = selectLanguage(state);
		this.logsQuery = selectLogsQuery(state);
		this.logs = selectAllLogs(state);
		this.activityOptions = selectActivities(state).map(activity => ({
			id: activity, name: [{lang: 'en', value: activity}, {lang: 'de', value: activity}]
		}));
		this.currentLanguage = selectProfileDefaultLanguage(state);

		if (this.updateNeeded) {
			this.updateNeeded = false;
			if (Object.keys(this.query).length === 0) {
				// if query is empty, navigate to initial query
				this.navigateToQuery(initialLogsQuery);
			} else {
				// if not, set the query in the store
				store.dispatch(setLogsQuery(this.query));
			}
		}

	}

	static get routes() {
		return getRoutes();
	}

	router(route, params, query, data) {
		this.params = params;
		this.query = query;
		this.data = data;
		this.profileId = params.id;

		// do not update dispatch immediately
		this.updateNeeded = route === 'admin-activities' && !deepEqual(this.query, query);

	}

	connectedCallback() {
		super.connectedCallback();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}


	resetFilters() {
		this.navigateToQuery(initialLogsQuery);
	}


	handleActivityChange(value) {
		this.navigateToQuery({...this.logsQuery, activities: value.length === 0 ? null : value[0]});
	}


	navigateToQuery(query) {
		// store.dispatch(navigateTo({route: 'admin-activities', params: this.params, query: trimObject(query)}));
		this.navigate(routeUrl('admin-activities', this.params, trimObject(query)));
		store.dispatch(setLogsQuery(trimObject(query)));
	}

	render() {
		return html`
			<div class="activityListFilter">
				${when(this.showFilter,
					() => html`
						<div class="filterContainer">
							<div class="filterItem">
								<arc-multiselect
									class="formInput activities"
									.singleSelect=${true}
									.title="${msg('Activities')}"
									.edit=${true}
									.options=${this.activityOptions}
									.value=${[this.logsQuery.activities] ?? null}
									@value-change=${(e) => this.handleActivityChange(e.detail.value)}
									.labelField=${'name'}
									.valueField=${'id'}
									.showSelectedCount=${false}
									.showCounters=${false}
									.emptyOption=${{label: msg('All'), value: null}}
								></arc-multiselect>
							</div>
							</div>
						</div>
					`)}

			</div>
		`;
	}
}
