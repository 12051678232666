import {createSelector} from '@reduxjs/toolkit';
import {selectProfileById} from './slices/profileSlice';
import {profileDisplayName} from './util/profileDisplayName';
import {selectAllArtworkTypes, selectAllMaterials, selectAllSerie, selectAllTags} from './slices/artworkSlice';

export const selectArtworkQuery = state => state.artwork.query;
export const selectArtworkCounters = state => state.artwork.counters;
export const selectTagSuggestions = state => state.artwork.tagSuggestions;

export const selectAccountsQuery = state => state.account.query;
export const selectLogsQuery = state => state.activity.query;
export const selectActivities = state => state.activity.activities;

// status
export const selectLanguage = state => state.status.lang;
export const selectTenant = state => state.status.tenant;
export const selectAvailableContentLanguages = state => state.status.availableContentLanguages;
export const selectCurrentContentLanguage = state => state.status.currentContentLanguage;

export const selectUserRole = state => state.account.data.account?.role;

//profile
export const selectCurrentProfileId = state => state.profile.currentProfileId;
export const selectCurrentProfile = state => selectProfileById(state, state?.profile?.currentProfileId);

// permissions
export const selectGlobalPermissions = state => state.account.globalPermissions;
export const selectProfilePermissions = state => state.account.profilePermissions;

export const selectRole = state => state.account.data.account?.role;
export const selectCurrentAccountId = state => state.account.data.account?.id;
export const selectCurrentAccount = state => state.account.data.account;
export const selectLoggedIn = state => state.account.data.loggedIn;

export const selectIsOwnerOfCurrentProfile = createSelector(
	[selectCurrentProfile, selectCurrentAccountId],
	(profile, accountId) => (profile?.ownerId === accountId)
);


export const selectProfileDisplayName = createSelector(
	[selectCurrentProfile],
	(profile) => (profile ? profileDisplayName(profile) : '')
);

export const selectProfileDefaultLanguage = createSelector(
	[selectCurrentProfile],
	(profile) => (profile ? profile.defaultLang : 'en')
);


export const selectFiltersWithCounters = createSelector(
	[selectArtworkCounters, selectAllArtworkTypes, selectAllMaterials, selectAllSerie, selectAllTags],
	(counters, artworkTypes, materials, series, tags) => {

		// static options
		const statusOptions = [
			{
				id: 'draft',
				name: [
					{lang: 'en', value: 'Draft'},
					{lang: 'de', value: 'Entwurf'}
				]
			},
			{
				id: 'published',
				name: [
					{lang: 'en', value: 'Dataset OK'},
					{lang: 'de', value: 'Datensatz OK'}
				]
			}
		];
		const visibilityOptions = [
			{
				id: 'draft',
				name: [
					{lang: 'en', value: 'Draft'},
					{lang: 'de', value: 'Entwurf'}
				]
			},
			{
				id: 'private',
				name: [
					{lang: 'en', value: 'Private'},
					{lang: 'de', value: 'Privat'}
				]
			},
			{
				id: 'public',
				name: [
					{lang: 'en', value: 'Publication permitted'},
					{lang: 'de', value: 'Veröffentlichung freigegeben'}
				]
			}
		];

		// merge status and visibility in one filter
		// apply counters from both
		let visibilityOptionsWithCounters = mapCountersToOptions(visibilityOptions, counters?.visibility);
		visibilityOptionsWithCounters = mapCountersToOptions(visibilityOptionsWithCounters, counters?.status);

		return {
			status: mapCountersToOptions(statusOptions, counters?.status),
			visibility: visibilityOptions,
			types: mapCountersToOptions(artworkTypes, counters?.typeId),
			materials: mapCountersToOptions(materials, counters?.materialId),
			tags: mapCountersToOptions(tags, counters?.tagIds),
			series: mapCountersToSeriesOptions(series, counters?.seriesId),
			counters: counters
		};
	}
);

function mapCountersToOptions(options, counters) {
	return options.map(option => {
		if (counters === undefined || counters.length < 2) {
			return option;
		}
		const counter = counters.find(c => c[0] + '' === option.id + '');
		// fix counters in Backend
		if (counter) {
			return {
				...option,
				counter: counter[1]
			};
		} else {
			return option;
		}
	});
}

function mapCountersToSeriesOptions(series, counters) {
	return series.map(serie => {
		if (counters === undefined || counters.length < 2) {
			return {
				id: serie.id,
				name: serie.title,
				counter: null
			};
		}
		const counter = counters.find(c => c[0] + '' === serie.id + '');
		return {
			id: serie.id,
			name: serie.title,
			counter: counter[1]
		};
	});
}
