
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    import {str} from '@lit/localize';

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's014930edaa0fa77d': `Start der Serie`,
's016f68d263953ec2': `Konto Löschen`,
's022ad53b239795d5': `Profilbild entfernen`,
's032cd9f1245d3645': `Maße`,
's0379fc73608ab971': `Fertig`,
's03848d257276adc8': `genaues Datum`,
's03aa3cfb08dff960': `Schlagwörter (thematisch)`,
's0491d2d22458cf73': `Maximale Anzahl an Videos überschritten`,
's063be496c545953f': `Anmerkung zur Signatur`,
's06b291180b5c2ef2': `Dieses Kunstwerk entfernen, wird es direkt aus Ihrer Liste von Kunstwerken ausblenden.`,
's06ef4755e61fa13f': `Login nicht erfolgreich. Bitte überprüfen Sie Ihre E-Mail und den Login-Code.`,
's071d99b3ab93bbcb': `Künstlergruppe`,
's072af2f9f637f289': `falsche Sprache`,
's0737332ca05ed6df': `Standardsprache für Inhalte`,
's08467b5f9b0bbe38': `Fügen Sie eigene Schlagwörter zu, nach denen Sie in der Übersicht filtern können`,
's087eb6b22eae8f9c': `Kunstwerk hinzufügen`,
's08c43a07b566d980': `hoch`,
's08d844177965c733': `Filter zurücksetzen`,
's09205907b5b56cda': `Nein`,
's09240e07b5b8d640': `ID`,
's0994965ba38c3639': `Inhalte können mehrsprachig eingegeben werden. Wählen Sie hier die Sprache aus, in der Sie Inhalte bearbeiten möchten.`,
's0c599150290fb992': `Kein Text`,
's0c6538ad32621450': str`Kunstwerk "${0}" entfernen, wird es direkt aus Ihrer Liste von
					Kunstwerken ausblenden.
				`,
's0d045841254adc83': `Kurzbeschreibung`,
's0e71a5c50cd56f90': `Profilbild hinzufügen`,
's0f0202c2780ce439': `Untertitel`,
's0f15b434f2922b08': `Ganze Zahl`,
's0f2cb376eeeaa423': `Jahr (Start)`,
's0f8a40190d30e7e3': `Lebenslauf bearbeiten`,
's10294464c853d9a8': `Ende der Serie`,
's1219d97b49650c05': `Sichtbarkeit`,
's12631aa651242caf': `Diese Nutzer:innen haben Berechtigungen, dieses Profil zu bearbeiten. Für Nutzer:innen die Sie selbst eingeladen haben, können Sie die Berechtigung zur Bearbeitung ändern.`,
's13e72a6392474063': `Profil gewechselt`,
's1641a1c90d9840ec': `Entwurf`,
's165d3919b108ec51': `Kurzbeschreibung Biografie`,
's17fc59d42b9bc362': `Konto Informationen`,
's1946c13a177a90a1': `Objektnummern`,
's19b937c88ca5d2b3': `Einladung senden`,
's1b23c41934894f30': `Erläuterung`,
's1b5bba3d9041629a': `Einstellungen speichern`,
's1ba65229592c08d9': `weiblich`,
's1c1134cbda4fe1ee': `Falsches Format`,
's1c43540556a6117c': `Die Einladung konnte nicht verschickt werden.`,
's1e61bece71b51008': `Gelöschte Einträge`,
's1f6a59a2cea667b0': `männlich`,
's1fa96bca92224298': `Willkommen zum`,
's202fffd5a2e1c595': `Länge`,
's21900f027f2d7cfa': `Durchmesser`,
's219c37011b334110': `Bilder / Medien hinzufügen`,
's242afb3c2ef36c4a': `Versicherungswert in €`,
's2468b69d10791c82': `Stadt`,
's24d03b95f001a869': `Kunstwerk entfernen`,
's24f39319b8853791': `Protokoll`,
's255857544a9d5ec0': `zurücksetzen`,
's26f91b6796e33111': `Wiederherstellen`,
's293aa6a6446fb153': `Export`,
's2a75c946ef4dcab7': `Verschiedenes`,
's2a94ce1cb475c9c2': `Sie können Personen einladen, die Ihnen bei der Erstellung dieses Profils helfen können. Diese
					können alle Kunstwerke und Profildaten einsehen und verändern.
				`,
's2c57913ffd36d298': `Vorname`,
's2ceb11be2290bb1b': `Abbrechen`,
's2d28af9a35724690': `Wenn Sie Zugriff auf mehrere Profile haben, wählen Sie hier das aktive Profil zur Bearbeitung aus.`,
's30c9440f8f06fc3c': `Maximale Anzahl an Dateien überschritten`,
's31b9c53d98feb660': `Login Code Anfordern`,
's31d694b87ea70f4d': `Falsches Datumsformat`,
's321c03399a143242': `Konto anlegen`,
's3231d0dc6fe30d4a': `Tiefe`,
's32ac2b6848504809': `Nationalität`,
's32b3ac51a9c31f47': `Zum Profil wechseln`,
's336c8e1678a8ff80': `Löschen`,
's3375a4920652b810': `Serien`,
's33b30147022d7f8c': `Code Eingeben`,
's345fbf4889ae0c7f': `Tas Kunstwerk enthält untergeordnete Einträge, bitte löschen Sie diese zuerst.`,
's34aa94e923bb3d75': `Eingabe nicht Teil der Menge`,
's34c31ef2544ae5ee': `Hier können Sie vorhandene Werknummern ergänzen, z.B. bestehende
					Sammlungsnummern oder von Ihnen selbst vergebene Werknummern.
				`,
's34d7c565f291023e': `Unverkäuflich`,
's355b21b89ce5d9c5': `Details`,
's35a9d77ceb9cfe4b': `z.B. Position, gestempelt ...`,
's37083e19c2f9c755': `Nr.`,
's383f38fbc7a28b77': `Datei zu groß`,
's38be98a354b0a135': `Signiert`,
's39b85a26b3f40ea4': `divers`,
's3a6129602c615ff1': `bearbeiten`,
's3c0d17bad169557b': `keine`,
's3cf89cb47fdde7e9': `Link`,
's3dc7f921134f8d35': `In der Werkverzeichnung des Kunstfonds können Sie Ihr Profil, Ihren Lebenslauf und
					Ihre Kunstwerke so einfach oder detailliert bearbeiten, wie Sie möchten.
				`,
's3f06e9abb6ebcb79': `Zeichen`,
's3f665d5e5aaa691c': `Profil Informationen`,
's402ed9d74111a751': `Datierung bis`,
's40cb8c9e0d13243b': `Weitere Angaben zu Material oder Technik`,
's43e372d3fcabbd0a': `Basisattribute`,
's45ab758ffe13fd9b': `Serien anlegen`,
's4650b3861340a401': `eingeladen von`,
's477bc7abb235cce6': `Filter ausblenden`,
's47a2a1f01af73025': `ggf. Sterbedatum`,
's483ed8121e9b9476': `Diese Nutzer:innen wurden von Ihnen zur Bearbeitung der angegeben Profile eingeladen.`,
's4880dc3125543292': `Erwähnungen`,
's48e186fb300e5464': `Zeit`,
's49c6478ea452050b': `Lebenslauf konnte nicht gespeichert werden. Bitte beheben Sie die Fehler.`,
's4b1d1a1379d84161': `Adresse (Zusatz)`,
's4b8475efa7c73228': `Inventarnummer`,
's4caed5b7a7e5d89b': `Englisch`,
's4cd84215487b493a': `Diagonale`,
's4ced1bd295c3cac3': `E-Mail Adresse der einzuladenden Person`,
's4da81c26ca735d66': `Provenienz/Besitzchronik`,
's4e33f21fc24666c1': `hoch`,
's4fcd634b4ebc761b': `Abschnitt hinzufügen`,
's5096bdb5f882fb29': `Materialien`,
's517acbf1f96324f9': `Gewicht`,
's523f5d97bb322419': `Konto Einstellungen`,
's544474a78ecc7b19': `Datenexport`,
's5540c926a41a6be9': `Profil hinzufügen`,
's5590dbf7e425789d': `Bezeichnung`,
's572aee617031d781': `In der Werkverzeichnung der Stiftung Kunstfonds können Sie Ihr Profil und Ihre Kunstwerke einpflegen und bearbeiten.`,
's58d4bced0b668ae6': `Keine Zahl`,
's596fa32cf6aa5810': `Standort`,
's5a41c3a63f1c18af': `Profil Einstellungen`,
's5ccc0b389bfe6048': `Keine Serien vorhanden`,
's5d7d95ba0d2c4869': `Das Formular konnte nicht gespeichert werden. Bitte beheben Sie die Fehler.`,
's5d929ff1619ac0c9': `Suche`,
's5e8250fb85d64c23': `Schließen`,
's5e9f4a47a1f2cd25': `Kunstwerk gespeichert`,
's5f175fdca113e5f1': `Weitere Objektnummer`,
's6367df083bd10e6c': `Nutzer:innen mit Berechtigungen für dieses Profil`,
's63819a470e9c99f9': `Beim Formular bleiben`,
's63b877fd01da50e6': str`Der Account "${0}" wird sofort gelöscht.`,
's63d894b1ddb06289': `Beschreibung`,
's63e457bc703a3030': `E-Mail`,
's63e71d20d1eaca93': `Deutsch`,
's64a6dcf9cd66376f': `Das Formular konnte nicht gespeichert werden. Bitte beheben Sie die Fehler.`,
's64eb05dfa49088da': `Konten verwalten`,
's64ef2a6c2dd1d3d1': `Bearbeiten`,
's65bbdef13ec0b7ff': `Titel eingeben`,
's65bf0994a56d0c51': `Hier können Sie einen kurzen Fließtext eintragen. Für die tabellarische Auflistung z.B. der
					Ausstellungshistorie verwenden Sie den Bereich Lebenslauf.
				`,
's663d390fd1b75d7e': `Besitz`,
's67749057edb2586b': `Abmelden`,
's68631cfbcbebfc30': `Keine Konten gefunden`,
's69201fa8972b3e66': `Das Kunstwerk enthielt keine Daten - es wurde permanent gelöscht.`,
's693bdd3f515eca11': `Institution oder Ort`,
's6a17d95381662d21': `Material/Technik`,
's6b138c631a7d2a2c': `Änderungen verwerfen`,
's6c307b439df73478': `Alle eingegeben Daten und hochgeladenen Medien werden in einem ZIP-Archiv zusammengepackt. Sie
					erhalten einen Link zum Download an die für Ihren Account verwendete Email-Adresse
				`,
's6c3970ea23f6d93a': `Profil Berechtigungen`,
's6c3daaac4eed12f9': `Änderungsprotokoll`,
's6c76b294d740446c': `Aktivität`,
's6cbf5b3254dcbbf3': `Code nochmal anfordern`,
's6cedf419e20c815a': `Telefon`,
's6d8c02aee480af7a': `Menü`,
's6e0f65a28704ec9f': `Name des Abschnitts`,
's70d885806b56289e': `aktiviert`,
's72f2a02e2a5a4ee3': `Bilder / Medien`,
's72f7fe91ebf6cddd': `Copyright/Courtesy`,
's73cdb5bba903a27f': `Ersetzen`,
's743edce4a1ed0b34': `Ist aktiviert`,
's744bab01318e5b27': `Konto speichern`,
's74bec541bd25310f': `Kunstwerk`,
's7565a1a1c29dabee': `undatiert`,
's7575ecc2f357b025': `Serie speichern`,
's77585085193f50c2': `Falsches Linkformat`,
's7858dcc121b436fc': `Das Profil konnte nicht gespeichert werden. Bitte beheben Sie die Fehler.`,
's7c34d7a176d95aa0': `Jahr (Ende)`,
's7c61df443de97bf6': str`Datei ist länger als ide maximale Länge von
					${0}
					Minuten für
					${1}
					Dateien
				`,
's7c6c090d491c4cdd': `Sie haben das Limit an Videodateien erreicht`,
's7d38fc29b20f951b': `Basisinformation`,
's7d8d449c7a0483c8': `Keine Bilder / Medien vorhanden`,
's7dfa029dab05b042': `Kunstwerke bearbeiten`,
's7f59f507e02a2f38': `Ansehen`,
's803ce7e1a2294d50': `Dateien`,
's804ca9ce39d5c56b': `Code Anfordern`,
's814ca8490eb230c0': `Kunstwerk hinzugefügt`,
's821ef36d63a8a111': `Zuletzt geändert`,
's82665b2ffabc9c0a': `Website`,
's832ef3a7593a8b05': `Bildunterschrift`,
's846b3790b6e25c1c': `Konto`,
's855aaab4663315b4': `Kunstwerke`,
's8641b6c3688a0cec': `Profil bearbeiten`,
's8677baad3bf6b295': `Lebenslauf speichern`,
's87a55464116c3f48': `runter`,
's87bae2710b2492c3': `Kontakt`,
's88ceb91846876e6f': `z.B. geschätzt, Dekade`,
's8a0e1c7e1ec3133b': `Testprofil`,
's8a6c0085cb6c64a0': `Geschlecht`,
's8ac6ca24f22e5e3f': `Verkauft`,
's8d5e9fed33847725': `Legen Sie fest, ob die Daten noch bearbeitet werden, das Kunstwerk nur für Sie sichtbar ist und ob das Kunstwerk öffentlich angezeigt werden darf.`,
's8d8622e68bd10109': `Bearbeiter:in einladen`,
's90c91b0f7a13cb65': `Das Formular enthält ungespeicherte Änderungen. Wollen Sie es wirklich verlassen?`,
's918bfaab4d882918': `Export in Vorbereitung. Bitte überprüfen Sie Ihre E-Mail.`,
's927497135cdabe78': `Unbenannt`,
's92921878e886e36d': `Laufzeit`,
's92fef0e2fba7468c': `Spracheinstellungen`,
's931ba7d0f5588be8': `Standard Sprache`,
's93c046d8c5bb2047': `Geburtstag`,
's948766ad031e1c27': `E-Mail`,
's9502410942da6b88': `Lebenslauf Eintrag`,
's95a75a2392af5780': `Nachname`,
's965c503c3e42fdfe': `Aktiviert`,
's98b32ef4a0856c08': `Kein Titel`,
's99f110d27e30b289': `Titel`,
's9aa73ae46dcc23b6': `Zusammenarbeit`,
's9b1394a1375404d4': `Ist Nachlass?`,
's9c470e19f779025e': `einmal angemeldet`,
's9cbe5dc90f48558d': str`Die Serie "${0}" wird sofort gelöscht. Die Kunstwerke in der Serie bleiben in der Liste der Kunstwerke.`,
's9cf7402fe920f7d1': `Land`,
's9d0a3b8de87941d8': `Kaufpreis in €`,
's9d8b8aa2b404c2c8': `Einstellungen`,
's9e92503bfc80d2d6': `Gattung/Medium des Kunstwerks`,
's9f5a5f23312798f0': `Mitglieder`,
'sa080ed80aad67329': `Datei zu lang`,
'sa0de761979ffad7b': `Datei könnte nicht hochgeladen werden.`,
'sa1dc38bb8e8339f3': `Künstlername`,
'sa2686cd9fcc26088': `Kunstwerk speichern`,
'sa3b6be7528aa09f6': `Sprachauswahl für die Eingabe. Um die Feldbezeichnungen/Maske zu wechseln, wechseln Sie UI Sprache im Konto-Menü.`,
'sa48f81f001b893d2': `Nutzer:in`,
'sa4bd17adfcc9780c': `Beschädigt`,
'sa512fd0aa10743a6': `Neues Konto`,
'sa5d2f9afa34fd3c3': `Login Code anfragen`,
'sa65a95e0199f2647': `Fehlerhafte Eingabe`,
'sa6f2645578b2d2bc': `Jahr`,
'sa75e401db49f2efc': `Aus Serie entfernen`,
'sa7e28eff7384ceee': `Als wichtig markieren`,
'sa84953c4bf74abca': `Serien bearbeiten`,
'sa87645df04d5260a': `Das Kunstwerk wurde nach Gelöschte Einträge verschoben. Es wird in 7 Tagen permant gelöscht.
				`,
'sae239213b7c70376': `Zustand`,
'sae42bf06e5c4bbe5': `E-Mail eingeben`,
'saf0bb10217436af6': `Protokoll`,
'saffa23915bede5a1': `PLZ`,
'sb061ff5a347a296e': `Profil`,
'sb0f8ba7eed402d21': `Lebenslauf gespeichert`,
'sb28076159e581755': `Bilder / Medien bearbeiten`,
'sb2d399c56e156311': `Veröffentlichung freigegeben`,
'sb3d83599db5a09be': `Kunstwerke in dieser Serie`,
'sb971debc37bf0b54': `Eigentümer`,
'sb9cdfae56d72cfe2': `Provenienz`,
'sbbb97b1c63507dc0': `Nummer`,
'sbc02a7fd82592275': `Dateiformate und Einschränkungen:`,
'sbc2cea55676b3aca': `Geben Sie die E-Mail Adresse ein, an die der Login Code gesendet werden soll.`,
'sbc6953a36ced943f': `Datenschutzbestimmungen`,
'sbcb056c74785d2c5': `(Unbenannt)`,
'sbe9e66b2467b273b': `Kunstwerk Berechtigungen`,
'sc14632800a845f4a': `Tags auswählen`,
'sc21fb6442d110d9a': `Eingeladene Bearbeiter:innen`,
'sc2c901ea4b99a96a': `Nachlass`,
'sc2d94a6963318685': `Künstler:innen-Profil bearbeiten`,
'sc3693cd85fbd07d3': `Kein Boolean`,
'sc4769ec2c49fe99b': `Ausstellungschronik/Leihchronik`,
'sc4801f580ac4e73d': `Operator`,
'sc4bbedc293a299bb': `Datei nicht vorhanden`,
'sc592307ea80f16b9': `Unbekannt`,
'sc5a11c2dd9ab8cec': `Privat`,
'sc5b9d5ea3bd15972': `Diese Nummer wird bei der Verzeichnung automatisch aufsteigend vergeben`,
'sc622b6f2e8694aac': `Bilder / Medien speichern`,
'sc6a7cb64b423cffa': `Verliehen`,
'sc7bcd1669fd8e336': `Bestandteil hinzufügen`,
'sc8da3cc71de63832': `Anmelden`,
'sc93a9aa3e5bcbf5d': `Profil speichern`,
'sca08a0a32920416b': `Keine Kunstwerke gefunden.`,
'sca7fed2bef53cb99': `Rolle`,
'sca974356724f8230': `Anmelden`,
'scaf5b41120861d9a': str`Der Login-Code konnte nicht gesendet werden`,
'scb489a1a173ac3f0': `Ja`,
'scb7ed089a649241c': `Neues Konto anlegen`,
'scbbbf3cce4237b03': `Pflichtfeld`,
'scc316262f13a1da8': `Keine Zahl`,
'sce8271050c5b61bb': `Adresse (Straße, Nr)`,
'scf8fcc133a2f8f5b': `Profilname eingeben`,
'scfa9e1e719a45ae3': `Maximale Länge Überschritten`,
'scfce1140fb4a263f': `Profil auswählen`,
'sd1779b0fe8df51f2': `Hinweise zum Art Handling`,
'sd1f0ec5501d4750b': `Anmerkung zum Zustand`,
'sd1f44f1a8bc20e67': `E-Mail`,
'sd24d2099f6f4a88b': `Tabellarischer Lebenslauf`,
'sd33f062c19e2e331': `Name`,
'sd55bb7d228718163': `keine E-Mail Adresse`,
'sd600892f7fabe04c': `Entwurf`,
'sd80496e9c65f1366': `Anmerkungen`,
'sd9aa257179753e02': `Datei hochladen`,
'sda5c7c6d6aeb3699': `Die Einladung wurde erfolgreich verschickt.`,
'sdc04126761bb7455': `runter`,
'sdc673e73b5c13aea': `Löschen`,
'sdcb9b5b53f8c8fc7': `Entfernte Kunstwerke ohne Daten werden direkt, mit Angaben nach einer Frist von sieben Tagen
					gelöscht.
				`,
'sdf4db55aad7f2064': `Ungespeicherte Änderungen`,
'se077a13a6f7be5e3': `Gründungsjahr und ggf. Jahr für Enddatum`,
'se17f76f493601b62': `https://... (Direkter Link zum Kunstwerk)`,
'se2464636aebe3629': `Unbeschädigt`,
'se2abc0f78904006c': `Zu viele Einträge`,
'se330c71fbddb77c9': `Geburtsort`,
'se3e144f19cb4e18f': `einmal angemeldet`,
'se40b5a4765a49b54': str`Datei größer als die maximale Größe von
					${0}
					für${1}.
				`,
'se48719d765fc8fde': `Serie Löschen`,
'se48bd9e6c2a3109d': `Konten`,
'se58ac13e33811f4c': `Stadt, Land`,
'se625ae9f34125ec4': `Bitte wählen`,
'se629b41ac62684a1': `zusätzliche Urheberrechte`,
'se68398e3c2c760b2': `Code`,
'se6a9371ded4b10d6': `Bestandteile`,
'se7238e44f9dcacee': `Jahr`,
'se77e76829df75be9': `Serie hinzufügen`,
'se7bee6e9a9b5394c': `Privat`,
'se80bc227790b7f05': `Hier können Sie Werke Ihren Serien zuordnen. Zum Anlegen einer Serie gehen Sie auf Serie anlegen.`,
'se80d078fe2e23924': `Kein Array`,
'se82d6481ffb5248f': `Mediendaten gespeichert`,
'se9159fcb6249fa3f': `Filter anzeigen`,
'se926a1be4644b09d': `Angemeldet bleiben`,
'sea4f08110bb8f15d': `Entfernen`,
'seae1c1bb7c02a640': `error/invalidCsrfToken`,
'seb4bdd2a26f2ea23': `Bitte auswählen`,
'sebec66b8aa58f583': `Keine Zahl`,
'sec72dce4f74b5328': `Datierung von`,
'sec7b97c2e906c8fc': `Eingeben...`,
'seca29ce17b31f70d': `Sichtbarkeit`,
'see2fcaedf9743ec6': `Ort`,
'sef16aaaba716aa7d': `Anzahl an Objekten`,
'sef49aec68fd1dc66': `Name`,
'sefe9b81e0f06a907': `Keine Aktivitäten.`,
'sf0175a45c199e862': str`Code gesendet an ${0}`,
'sf0d50c9807f01f8f': `Ich akzeptiere die`,
'sf21fb4f4b9e8911f': `Breite`,
'sf22b76ecc1741ca7': `Organisation`,
'sf3282d80d86fcdc4': `PDF ansehen`,
'sf3fe6b5fdb85d50a': `löschen`,
'sf4b72165f60f5b60': `Öffentlich`,
'sf61497d51f2c663f': `Gelöschte Einträge`,
'sf65616fb463fe2e1': `Keine eingeladenen Nutzer:innen.`,
'sf678df8359ba6257': `Fehler beim Hinzufügen des Kunstwerks`,
'sf6947e1f919e6815': `Minimale Länge Unterschritten`,
'sf74160a9dc86ab8f': `Auflösung`,
'sf76a183603d87542': `Profil für Nutzer:in anlegen`,
'sf78636872ec15cbb': `Geben Sie hier den Login Code ein, den Sie per Email erhalten haben.
					Klicken Sie "Angemeldet bleiben" an, wenn Sie auf Ihrem Rechner langfristig angemeldet bleiben wollen.`,
'sf8e156354e5bacb8': `Aktivitäten`,
'sf993bb199fefbe04': `Alle`,
'sf9e39190a7ba37b4': `Profil gespeichert`,
'sfa766d8b35773ac0': `ggf. Sterbeort`,
'sfa889eb8ea9148b1': `Datierung`,
'sfac15b89e0668996': `Duplizieren`,
'sface44df32238223': `Ihr Profil`,
'sfb22e3d1056f6a30': `Maße`,
'sfb4034918f62ded7': `UI Sprache`,
'sfdd8a3510a8b1bed': `Videos`,
'sfde5ee686b1d3d02': `Urheberrechte`,
'sfe46f400c6b86658': `ansehen`,
'sfe7bf39909a48de6': `Serie`,
'sff489cc579ecaf83': `Datumshinweis`,
'sff9d51b6c5a73163': `Alternativer Text`,
'sffdadf0a576fd802': `Höhe`,
's7a851c97b5bc30b8': `error/notIntBool`,
's11366a6d9faff00f': `error/trueRequired`,
'sa0541ec14dd4b89c': `error/falseRequired`,
'sb8bf01b381b893bb': `error/oneRequired`,
'sb41210de4f64f7e1': `error/zeroRequired`,
'sdc8ff1d18f2ecc66': `error/notZeroPosInt`,
's24ed5ee1f343a2b4': `error/uploadPartial`,
's881e3183ff4b701d': `error/uploadMissingTmpDir`,
's860dbc68e0f2b4c8': `error/uploadUnableToWrite`,
'sc21dc776d25eb55a': `error/uploadExtensionFailure`,
'sc34b221a0b8bc676': `error/uploadGenericError`,
'sfb608c3ab924e85c': `error/uploadCategoryNotAllowed`,
's9739d36feb7c7948': `error/uploadTypeNotAllowed`,
'saae1c70e168b45b4': `Admin`,
's09242e07b5b90ca0': `Id`,
'scb6a9c1a1757adbc': `You`,
'scbf29ce484222325': ``,
'sf9e6e6ef197c2b25': `test`,
's2474e7fb1aec9f05': `Test`,
    };
  